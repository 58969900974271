import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faTrash, faPencil } from '@fortawesome/free-solid-svg-icons'
import { useAuthContext } from '../hooks/useAuthContext';
import Swal from 'sweetalert2';

//const BASE = "https://mental-fortitude-site.onrender.com"


//components
import WorkoutDetails from '../components/WorkoutDetails'

const BASE = "https://mental-fortitude-site.onrender.com"

//Displaying single workout page
const SingleWorkout = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isPending, setIsPending] = useState(true)
    const [workout, setWorkout] = useState(null)
    const {user} = useAuthContext()
    const [conDelete, setConDelete] = useState(false);

    useEffect(() => {
        const fetchWorkout = async () => {
            const response = await fetch(BASE + '/api/workouts/' + id, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setWorkout(json)
                setIsPending(false)
            }else{
                navigate('/login')
            }
        }
        fetchWorkout()
    }, [id, user, navigate])

    const handleDelete = async () =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            color: "#d4ba75",
            background: "#1d1c1c",
            iconColor: "#d4ba75",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
              setConDelete(true);
            }else{
                return
            }
          });
        if(!user || !conDelete){
            return
        }
            const response = await fetch(BASE + '/api/workouts/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
    
        if (response.ok){
            console.log(json)
            Swal.fire({
                title: "Deleted!",
                text: "Your workout has been deleted.",
                icon: "success",
                color: "#d4ba75",
                background: "#1d1c1c",
                confirmButtonColor: "#7286a0",
                iconColor: "#d4ba75"
              });
            navigate('/')
        }

    }

    const handleEdit = () => {
        navigate(`/edit/${id}`)
    }

    const handleHome = () =>{
        navigate('/')
    }
    return (
        <div className="single-workout">
            <div className="details-container">
                {isPending && <h1>Loading...</h1>}
                {workout && <div>
                    <WorkoutDetails workout={workout}/>
                    </div>}
                {workout && <div className="center-button">
                    <button className='home-button' onClick={handleHome}><FontAwesomeIcon icon={faHouse} /><span className='text-from-icon'>Home</span></button>
                    <button className='edit-workout-button' onClick={handleEdit}><FontAwesomeIcon icon={faPencil}/><span className='text-from-icon'>Edit</span></button>
                    <button className="delete-workout" onClick={handleDelete}><FontAwesomeIcon icon={faTrash}/><span className='text-from-icon'>Delete</span></button>
                    </div>}
            </div>
        </div>
    )
}

export default SingleWorkout;