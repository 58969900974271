import { useState } from "react";
import Swal from 'sweetalert2';
import SetsForm from '../components/SetsForm';

//Array used for keeping track of sets ids


const ExerciseForm = ({ exercises, title }) => {
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [rounds, setRounds] = useState('')
    const [duration, setDuration] = useState('')
    const [distance, setDistance] = useState('')
    const [hours, setHours] = useState('')
    const [min, setMin] = useState('')
    const [sets, setSets] = useState([])


    //keeps track of number of sets needed to generate based on number in rounds field
    var numSets = []

    var usedNums = []
    //function to get data from SetsForm with id(num)


    const getSets = (data, num) => {
        if(usedNums.includes(num)){
            const nextSets = sets.map((d, i) => {
                if(i === num){
                    return data
                } else{
                    return d
                }
            })
            setSets(nextSets)
        } else {
            usedNums.push(num)
            setSets([...sets, data])
        }

    }

    //handle click of add exercise button
    //instead of alert have an alert message that looks better and is bigger
    const handleClick = (e) => {
        e.preventDefault()
        if(sets.length !== numSets.length){
            Swal.fire({
                position: "center",
                title: "Missing sets?!?",
                text: "Go confirm those sets!",
                color: "#d4ba75",
                background: "#1d1c1c",
                confirmButtonColor: "#7286a0",
                icon: "error",
                iconColor: "#d4ba75"
            });
        } else {
            if (type === '' || name === '') {
                Swal.fire({
                    position: "center",
                    title: "Missing Info",
                    text: "Need exercise name and type, silly goose",
                    color: "#d4ba75",
                    background: "#1d1c1c",
                    confirmButtonColor: "#7286a0",
                    icon: "warning",
                    iconColor: "#d4ba75"
                });
            } else {
                    let data = { type, name, duration, distance, sets }
                    exercises(data)
                    setSets([])
                    setType('')
                    setName('')
                    setRounds('')
                    setDuration('')
                    setDistance('')
                    setMin('')
                    setHours('')
                    var selectBox = document.getElementById('exercise-type');
                    selectBox.selectedIndex = 0
                    Swal.fire({
                        position: "center",
                        title: "Exercise Added",
                        text: "Great work, keep it up dawg!",
                        color: "#d4ba75",
                        background: "#1d1c1c",
                        confirmButtonColor: "#7286a0",
                        icon: "success",
                        iconColor: "#d4ba75"
                    });
                }
            }  

    }

    //creates the correct number of SetsForms and passes the getSets function to children
    const sendSets = (rounds) => {
        for(let i=0; i<rounds; i++){
            numSets.push(<SetsForm getSets={getSets} num={i}/>)
        }
        return numSets
    }

    const calcTime = (thours, tminutes) => {
        var time = 0;
        time = Number(tminutes) + Number((thours * 60))
        console.log(time)
        setDuration(time)
    }


    return (
        <div className="exercise-form">
            <form className="create">
                <div className="eform-table">
                    <div className="eform-row">
                        <div className="eform-col-1">
                            <div><label>Type of Exercise (Lift, Cardio): </label></div>
                            <div><select name='exercise-type' id='exercise-type' onChange={(e) => setType(e.target.value)}>
                                <option value='Choose' selected disabled>Choose one...</option>
                                <option value='Lift'>Lift</option>
                                <option value='Cardio'>Cardio</option>
                            </select>
                            </div>
                        </div>
                        <div className="eform-col-2">
                            <label>Name of Exercise: </label>
                            <input
                                required
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                    </div>
                    <div className="eform-row">
                        <div className="eform-col-1">
                            {type === "Cardio" && <label>Duration of exercise (hours): </label>}
                            {type === "Cardio" && <input
                                type="number"
                                onChange={(e) => {
                                    setHours(e.target.value)
                                    calcTime(e.target.value, min)
                                }}
                                value={hours}
                            />} 
                            {type === "Cardio" && <label>Duration of exercise (minutes): </label>}
                            {type === "Cardio" && <input
                                type="number"
                                onChange={(e) => {setMin(e.target.value)
                                    calcTime(hours, e.target.value)
                                }}
                                value={min}
                            />} 
                        </div>
                        <div className="eform-col-2">
                            {type === "Cardio" && <label>Distance for exercise in miles: </label>}
                            {type === "Cardio" && <input
                                type="number"
                                onChange={(e) => setDistance(e.target.value)}
                                value={distance}
                            />} 
                        </div>
                    </div>
                    <div className="eform-row">
                        <div className="eform-col-1">
                            {type === "Lift" && <label>Number of Sets for Exercise: </label>}
                            {type === "Lift" &&
                            <input type="number"
                                min={1}
                                onChange={(e) => setRounds(e.target.value)}
                                value={rounds}
                            />}
                        </div>
                    </div>
                <div className="sets-table">
                    {rounds > 0 && sendSets(rounds)}
                </div>                        
                        
                        
                </div>
                <div className="add-exercise-container">
                    <button className='add-exercise-button' onClick={handleClick}>Add Exercise</button>
                </div>
            </form>
        </div>
    )
}
export default ExerciseForm;